import React from "react"

export interface ImageInfoProps {
  classStyle?: string
  position: "left" | "right"
  imageEffect?: "none" | "zoom" | "floatting"
  title: string
  description?: any
  image: string
  button?: { label: string; link: any; classStyle?: string }
}

const ImageInfo = (props: ImageInfoProps) => {
  return (
    <div className={`image-info ${props.classStyle}`}>
      <div className={`container`}>
        <div
          className={`image-info-text ${
            props.position === "left" ? "left" : "right"
          }`}
        >
          <div className="image-info-text-title">{props.title}</div>
          <div className="image-info-text-description">{props.description}</div>
          {props.button ? (
            <a
              className={`btn ${props.button.classStyle}`}
              href={props.button.link}
              target="_blank"
            >
              {props.button.label}
            </a>
          ) : (
            ""
          )}
        </div>
        <div
          className={`image-info-img ${
            props.position === "left" ? "right" : "left"
          }`}
        >
          <img
            className={
              props.imageEffect && props.imageEffect !== "none"
                ? props.imageEffect
                : ""
            }
            src={props.image}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageInfo
