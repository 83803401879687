import React from "react"

export interface OnlyBrandIntroProps {
  classStyle?: string
  brand?: string
  smoke?: string
  title?: string
}

const OnlyBrandIntro = (props: OnlyBrandIntroProps) => {
  return (
    <div className={`only-brand-intro ${props.classStyle}`}>
      <div className="only-brand-intro-brand">
        <div className="only-brand-intro-brand-extras">
          <img
            src={props.smoke}
            className="only-brand-intro-brand-extras-smoke"
          />
        </div>
        <img src={props.brand} className="only-brand-intro-brand-base" />
        <div className="only-brand-intro-brand-title">
          <img src={props.title} />
        </div>
      </div>
    </div>
  )
}

export default OnlyBrandIntro
