import React from "react"

export interface SectionTitleProps {
  classStyle?: string
  title: string
}

const SectionTitle = (props: SectionTitleProps) => {
  return (
    <div className={`section-title ${props.classStyle}`}>
      <h1> {props.title} </h1>
    </div>
  )
}

export default SectionTitle
