import * as React from "react"

import "../assets/scss/main.scss"
import OnlyBrandIntro from "../containers/only-brand-intro/OnlyBrandIntro"
import BaseLayout from "../layout/BaseLayout"

import Brand from "../assets/img/brand/base.png"
import Smoke from "../assets/img/brand/smoke.png"
import Lettering from "../assets/img/brand/lettering.png"
import InsideThePlanet from "../assets/img/games/inside-the-planet.png"

import TwitterIcon from "../assets/img/icons/twitter.png"
import MailIcon from "../assets/img/icons/mail.png"
import BehanceIcon from "../assets/img/icons/behance.png"
import LinkedinIcon from "../assets/img/icons/linkedin.png"
import WebsiteIcon from "../assets/img/icons/website.png"

import VictorReiner from "../assets/img/team/victor_reiner.jpg"
import ShubiArt from "../assets/img/team/shubi.jpg"

import ImageInfo from "../containers/image-info/ImageInfo"
import SectionTitle from "../components/section-title/SectionTitle"
import Team from "../containers/team/Team"
import Footer from "../components/footer/Footer"
import { Helmet } from "react-helmet"

const insideThePlanet = {
  title: "Inside the planet",
  description: (
    <p>
      Juego de plataformas 2D para Android en el que encarnarás a Moku, un
      adorable oni de un ojo que intenta viajar por todo su sistema. Recuperando
      piedras y volando entre mundos. Juego de puzzles y habilidad con más de 60
      niveles y hasta tres grupos de habilidad.
    </p>
  ),
  image: InsideThePlanet,
  button: {
    label: "Saber más",
    link:
      "https://play.google.com/store/apps/details?id=com.cocidoware.insidetheplanet",
  },
}

const socials: { image: string; link: string }[] = [
  {
    image: TwitterIcon,
    link: "https://twitter.com/cocidoware",
  },
  {
    image: MailIcon,
    link: "mailto:cocidoware@gmail.com",
  },
]

const team = {
  description: "¡Pequeño pero matón!",
  team: [
    {
      name: "Victor Reiner",
      role: "Desarrollador, EFX, Música",
      image: VictorReiner,
      socials: [
        { icon: TwitterIcon, link: "https://twitter.com/victor_reiner" },
        {
          icon: LinkedinIcon,
          link:
            "https://www.linkedin.com/in/victor-mu%C3%B1oz-reyes-108930117/",
        },
        { icon: WebsiteIcon, link: "https://victorreiner.com" },
      ],
    },
    {
      name: "Shubi",
      role: "Artista",
      image: ShubiArt,
      socials: [
        { icon: TwitterIcon, link: "https://twitter.com/shubi_art" },
        { icon: BehanceIcon, link: "https://www.behance.net/Shubi_Art" },
      ],
    },
  ],
}

const IndexPage = () => {
  return (
    <BaseLayout classTheme="theme-1" socials={socials}>
      <Helmet>
        <title>Cocidoware</title>
        <meta
          name="description"
          content="Estudio de desarrollo de videojuegos"
        />
        <meta property="og:title" content="Cocidoware" />
        <meta property="og:url" content="https://cocidoware.com/" />
        <meta
          name="og:description"
          content="Estudio de desarrollo de videojuegos"
        />
        <meta property="og:image" content={Brand} />
      </Helmet>
      <OnlyBrandIntro brand={Brand} smoke={Smoke} title={Lettering} />
      <SectionTitle title="Juegos" />
      <ImageInfo
        position="left"
        imageEffect="zoom"
        title={insideThePlanet.title}
        description={insideThePlanet.description}
        image={insideThePlanet.image}
        button={insideThePlanet.button}
      />
      <SectionTitle title="Equipo" />
      <Team {...team} />
      <Footer text="Victor Reiner © 2021" />
    </BaseLayout>
  )
}

export default IndexPage
