import React from "react"

export interface FooterProps {
  classStyle?: string
  text: string
}

const Footer = (props: FooterProps) => {
  return <footer className={`footer ${props.classStyle}`}>{props.text}</footer>
}

export default Footer
