import React from "react"

export interface TeamCardProps {
  classStyle?: string
  name: string
  role: string
  image?: string
  socials?: { icon: string; link: string }[]
}

const TeamCard = (props: TeamCardProps) => {
  const socials = props.socials
    ? props.socials.map((item, index) => {
        return (
          <a
            key={`${props.name}_key_${index}`}
            className="team-card-socials-item"
            href={item.link}
            target="_blank"
          >
            <img src={item.icon} />
          </a>
        )
      })
    : []

  return (
    <div className={`team-card ${props.classStyle}`}>
      <img className="team-card-avatar" src={props.image} />
      <h5 className="team-card-name">{props.name}</h5>
      <h6 className="team-card-role">{props.role}</h6>
      <div className="team-card-socials">{socials}</div>
    </div>
  )
}

export default TeamCard
