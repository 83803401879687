import React from "react"
import TeamCard, { TeamCardProps } from "../../components/cards/TeamCard"

export interface TeamProps {
  classStyle?: string
  title?: string
  description?: any
  team: TeamCardProps[]
}

const Team = (props: TeamProps) => {
  const list = props.team.map((t, index) => {
    return (
      <TeamCard
        key={`team_${index}`}
        name={t.name}
        role={t.role}
        image={t.image}
        socials={t.socials}
        classStyle="team-item"
      />
    )
  })
  return (
    <div className={`team ${props.classStyle}`}>
      <div className={`container`}>
        <div className="team-text">
          <div className="team-text-title center">{props.title}</div>
          <div className="team-text-description center">
            {props.description}
          </div>
        </div>
        <div className="team-cards">
          <div className="team-cards-list">{list}</div>
        </div>
      </div>
    </div>
  )
}

export default Team
